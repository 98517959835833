import $ from 'jquery';
import { Autoplay, EffectFade, Navigation, Pagination, Swiper, Thumbs } from 'swiper';

import { $Elements, Events, Durations } from './constants';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Carousel ----------

	$('.wines-section').each(function() {

		const slider = this.querySelector('.content-carousel');
		const pagination = this.querySelector('.pagination-carousel');

		if(pagination && !pagination.swiper) {

			const paginationSwiper = new Swiper(pagination, {
				speed: Durations.default,
				direction: "horizontal",
				breakpoints: {
					1024: {
						direction: "vertical",
					},
				},
				loop: false,
				observer: true,
				slidesPerView: 3,
				threshold: 10,
				watchSlidesProgress: true,
				modules: [Navigation],
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});

			if(slider && !slider.swiper) {

				const mainSwiper = new Swiper(slider, {
					speed: Durations.default,
					modules: [Thumbs, EffectFade],
					slidesPerView: 1,
					loop: false,
					observer: true,
					thumbs: {
						swiper: paginationSwiper,
					},
					effect: 'fade',
					fadeEffect: {
						crossFade: true,
					},
				});
			}
		}
	});


	// ---------- Carousel ----------

	$('.content-section .section-carousel, .content-section .column-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.default,
				loop: true,
				observer: true,
				modules: [Navigation, Pagination],
				pagination: {
					el: this.querySelector('.swiper-pagination'),
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

	// ---------- Slideshow ----------

	$('.content-section .section-slideshow, .content-section .column-slideshow').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.default,
				loop: true,
				observer: true,
				autoplay: true,
				effect: 'fade',
				modules: [Autoplay, EffectFade, Navigation, Pagination],
				fadeEffect: {
					crossFade: true,
				},
				pagination: {
					el: this.querySelector('.swiper-pagination'),
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});
});
