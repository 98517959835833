import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Animations, Events, Classes, Elements } from '../global/constants';

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});
		});
	});

	// ---------- Header menu ----------

	$Elements.HeaderWrapper.each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: Elements.PageWrapper,
					start: this.offsetHeight / 2,
					toggleClass: {
						targets: this,
						className: Classes.menuLight
					},
				}
			});
		});
	});

	// ---------- Image animation (appears) ----------

	$('.content-section.animated-image').each(function() {

		Animations.global.push(() => {

			const self = $(this);
			const target = self.find('.image-cover');

			target.each(function(index) {
				const delay = index * 0.5;
				$(this).css('transition-delay', delay+'s')
			})

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: {
						targets: target,
						className: Classes.active
					},
				}
			});
		});
	});

	// ---------- Content Sections ----------

	$('.parallax-section:not(fixed):not(.transition-clone)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});
	});

	$('.images-text-horizontal-section, .images-text-vertical-section').each(function() {

		Animations.mobile.min.push(() => {

			const image = this.querySelector(':scope .small-image-column');

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
						// markers: true,
					},
				});

				timeline.fromTo(image, {
					y: () => (this.classList.contains('images-text-vertical-section')) ? 300 : 0
				}, {
					y: () => (this.classList.contains('images-text-vertical-section')) ? image.clientHeight / 1.2 : image.clientHeight / 4
				});
			}
		});
	});

	// ---------- Hero (Rotate Text) ----------

	$('.home-hero-section, .range-hero-section').each(function() {

		Animations.global.push(() => {

			const logo = document.querySelector('.logo-circle');

			if(logo) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top 50px',
						endTrigger: "footer",
						end: 'bottom top',
						invalidateOnRefresh: true,
						scrub: true,
						//markers: true,
					},
				});

				timeline.fromTo(logo, {
					rotate: 0,
					transformOrigin: '50% 50%',
				}, {
					rotate: 1200,
					transformOrigin: '50% 50%',
				});
			}
		});
	});
};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());
