import $ from 'jquery';

export const getCSSVariable = (variable, element = document.documentElement) => getComputedStyle(element).getPropertyValue(variable);

export const Events = {
	lazyload: 'lazyload.refresh',
	resize: 'resize',
	page: {
		loaded: 'page.loaded',
		enter: 'page.enter',
		leave: 'page.leave',
		transition: 'page.transition',
	},
	transition: {
		start: 'transitionstart',
		end: 'transitionend',
		refresh: 'transition.refresh'
	},
	animation: {
		start: 'animationstart',
		end: 'animationend',
		refresh: 'animation.refresh'
	}
};

export const EventsPromises = {
	transition: {
		start: (element) => new Promise((resolve, reject) => $(element).one(Events.transition.start, resolve)),
		end: (element) => new Promise((resolve, reject) => $(element).one(Events.transition.end, resolve)),
	},
	animation: {
		start: (element) => new Promise((resolve, reject) => $(element).one(Events.animation.start, resolve)),
		end: (element) => new Promise((resolve, reject) => $(element).one(Events.animation.end, resolve)),
	}
};

export const Breakpoints = {
	xs: parseInt(getCSSVariable('--breakpoint-xs'), 10),
	sm: parseInt(getCSSVariable('--breakpoint-sm'), 10),
	md: parseInt(getCSSVariable('--breakpoint-md'), 10),
	lg: parseInt(getCSSVariable('--breakpoint-lg'), 10),
	xl: parseInt(getCSSVariable('--breakpoint-xl'), 10),
	xxl: parseInt(getCSSVariable('--breakpoint-xxl'), 10),
	mobile: parseInt(getCSSVariable('--breakpoint-mobile'), 10),
	menu: parseInt(getCSSVariable('--breakpoint-menu'), 10),
};

export const Elements = {
	get LoadingBar() {
		return document.querySelector('.loading-bar');
	},
	get LoadingOverlay() {
		return document.querySelector('.loading-overlay');
	},
	get MainWrapper() {
		return document.querySelector('.main-wrapper');
	},
	get PageWrapper() {
		return document.querySelector('.page-wrapper');
	},
	get MenuWrapper() {
		return document.querySelector('.menu-wrapper');
	},
	get HeaderWrapper() {
		return document.querySelector('.header-wrapper');
	},
};

export const $Elements = {
	get window() {
		return $(window);
	},
	get document() {
		return $(document);
	},
	get html() {
		return $(document.documentElement);
	},
	get body() {
		return $(document.body);
	},
	get LoadingBar() {
		return $(Elements.LoadingBar);
	},
	get LoadingOverlay() {
		return $(Elements.LoadingOverlay);
	},
	get MainWrapper() {
		return $(Elements.MainWrapper);
	},
	get PageWrapper() {
		return $(Elements.PageWrapper);
	},
	get MenuWrapper() {
		return $(Elements.MenuWrapper);
	},
	get HeaderWrapper() {
		return $(Elements.HeaderWrapper);
	},
};

export const Classes = {
	active: 'active',
	leaving: 'leaving',
	loading: 'loading',
	loaded: 'loaded',
	initialLoad: 'initial-load',
	hidden: 'd-none',
	invisible: 'visually-hidden',
	transitionClone: 'transition-clone',
	moving: 'moving',
	show: 'show',
	menuOpen: 'menu-open',
	menuLight: 'menu-light'
};

export const Durations = {
	default: parseInt(getCSSVariable('--transition-duration'), 10),
	page: parseInt(getCSSVariable('--page-transition-duration'), 10),
	animations: parseInt(getCSSVariable('--animations-duration'), 10),
};

export const BarbaLinks = 'a:not([target="_blank"]):not([href*="#"]):not([href^="mailto:"]):not([href^="tel:"]):not(.void-link):not([download]):not([href=""]):not([hreflang]):not([target="lightbox"]):not([target^="lightbox-gallery"]):not([target="scroll-to"]):not([data-scroll-to])';

export const MapTiles = {
	url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
	options: {
		attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
		subdomains: 'abcd'
	}
};

export let Animations = { global: [] };
Object.entries(Breakpoints).forEach(([breakpoint, value]) => Animations[breakpoint] = { width: value, min: [], max: [] });
